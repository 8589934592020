@import '../node_modules/ngx-toastr/toastr'

.rezbot-session-bookit
    @import '../node_modules/@ng-select/ng-select/scss/default.theme.scss'
    @import '../node_modules/angular-calendar/scss/angular-calendar.scss'
    @import 'tailwind.scss'

    .cal-month-view
        background: transparent !important

        .cal-day-cell
            &.closed
                pointer-events: none

            &:not(.closed) > div > .month-day
                cursor: pointer

        .cal-days
            border: none

            .cal-cell-row
                border: none

        .available
            .wcs-month-day, .wcs-session
                background-color: #669900

        .closed
            .wcs-month-day, .wcs-session
                background-color: #cc0000
                opacity: 0.6

        .limited
            .wcs-month-day, .wcs-session
                background-color: #ffaa00

        .cal-day-cell
            min-height: auto

            &:not(:last-child)
                border-right: none

            &.cal-today
                background: none

        .cal-cell-row
            &:hover
                background: none

            .cal-cell
                &:hover
                    background: none

geronigo-session-bookit, rezbot-session-bookit
    font-size: 16px !important
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"

    *
        backface-visibility: visible
        background: 0
        border: none
        box-shadow: none
        caption-side: top
        clear: none
        float: none
        font-style: inherit
        font-variant: inherit
        font-weight: inherit
        font-size: inherit
        text-transform: inherit
        height: auto
        letter-spacing: normal
        line-height: normal
        list-style: none
        max-height: none
        max-width: none
        min-height: auto
        min-width: auto
        opacity: 1
        overflow: visible
        position: static
        text-align: inherit
        text-align-last: auto
        text-decoration: none
        text-decoration-color: inherit
        text-indent: 0
        text-shadow: none
        transform: none
        transition: none
        vertical-align: baseline
        visibility: visible
        white-space: normal
        width: auto
        word-spacing: normal
        z-index: auto

        box-sizing: border-box
        margin: 0
        padding: 0
        background-color: transparent
        scrollbar-width: none

    .wcs-spin
        animation-name: spin
        animation-duration: 1000ms
        animation-iteration-count: infinite
        animation-timing-function: linear

    .wcs-flex-basis-0
        flex-basis: 0

    fa-icon
        display: inline-block

    button
        .wcs-hint
            display: none

        &:disabled
            background: rgba(255, 255, 255, 0.5) !important
            cursor: initial

            &:hover
                background: rgba(255, 255, 255, 0.5) !important
                opacity: 1 !important

                & > .wcs-hint
                    display: block

                & > .wcs-text
                    display: none

    .wcs-spinner-loader
        margin: 0 5px
        text-align: center

        & > div
            width: 5px
            height: 5px
            background-color: #2d3748
            vertical-align: middle
            border-radius: 100%
            display: inline-block
            animation: sk-bouncedelay 1.4s infinite ease-in-out both

        &.wcs-spinner-inverse > div
            background-color: #ffffff

        .wcs-bounce2, .wcs-bounce3
            margin-left: 2px

        & .wcs-bounce1
            animation-delay: -0.32s

        & .wcs-bounce2
            animation-delay: -0.16s

    .wcs-stripe
        &:nth-child(2n)
            background: #212529

        &:nth-child(2n - 1)
            background: #2c3034

        &:not(:first-child)
            border-top: 1px solid #32383e

    .wcs-hover-bg
        &:hover
            background: rgba(74, 85, 104, 0.2) !important

    .wcs-main-gradient
        background: #ffffff

    .wcs-table-divisor
        border-bottom: 1px solid rgba(74, 85, 104, 0.3)

    ng-select.wcs-custom
        .ng-select-container
            background: transparent !important
            border: 1px solid #4a5568 !important

        .ng-placeholder
            color: rgba(74, 85, 104, 0.5) !important

        .ng-arrow
            border-color: #4a5568 transparent transparent !important

        .ng-input input
            color: #4a5568 !important

        .ng-clear
            color: #4a5568 !important

    ng-select.wcs-low-alpha
        .ng-select-container
            border: 1px solid rgba(74, 85, 104, 0.5) !important

    .wcs-pos-center
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)

    @keyframes spin
        from
            transform: rotate(0deg)
        to
            transform: rotate(360deg)

    @keyframes sk-bouncedelay
        0%, 80%, 100%
            transform: scale(0)
        40%
            transform: scale(1.0)

    .wcs-custom-height
        .ng-select-container
            height: 58px !important

    .wcs-calendar
        position: absolute
        top: 100%
        right: 0
        left: -150px

    input, textarea
        height: auto !important
        text-align: left !important

    input[type="checkbox"].ng-invalid.ng-touched + span
        color: #c53030

    input[type="text"], .mat-input-element
        background-color: transparent !important
        margin: 0 !important
        height: auto !important
        padding: 0 !important

    @media only screen and (max-width: 640px)
        .wcs-calendar
            position: fixed
            top: 0
            left: 0
            right: 0
            bottom: 0
            background: rgba(0, 0, 0, 0.5)
            display: flex
            align-items: flex-start
            justify-content: center
            padding-top: 5rem
            z-index: 50

            app-calendar
                min-width: 300px