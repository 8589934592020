*,
::before,
::after {
  box-sizing: border-box;
}

html {
  -moz-tab-size: 4;
  -o-tab-size: 4;
     tab-size: 4;
}

html {
  line-height: 1.15;
  
  -webkit-text-size-adjust: 100%;
  
}

body {
  margin: 0;
}

body {
  font-family:
		system-ui,
		-apple-system, 
		'Segoe UI',
		Roboto,
		Helvetica,
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji';
}

hr {
  height: 0;
  
  color: inherit;
  
}

b {
  font-weight: bolder;
}

code {
  font-family:
		ui-monospace,
		SFMono-Regular,
		Consolas,
		'Liberation Mono',
		Menlo,
		monospace;
  
  font-size: 1em;
  
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

button,
input,
select,
textarea {
  font-family: inherit;
  
  font-size: 100%;
  
  line-height: 1.15;
  
  margin: 0;
  
}

button,
select {
  
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  
  outline-offset: -2px;
  
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  
  font: inherit;
  
}

summary {
  display: list-item;
}

dd,
h1,
h5,
hr,
p {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  
  line-height: 1.5;
  
}

body {
  font-family: inherit;
  line-height: inherit;
}

*,
::before,
::after {
  box-sizing: border-box;
  
  border-width: 0;
  
  border-style: solid;
  
  border-color: currentColor;
  
}

hr {
  border-top-width: 1px;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button,
[role="button"] {
  cursor: pointer;
}

:-moz-focusring {
  outline: auto;
}

h1,
h5 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button,
input,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

code {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

svg,
object {
  display: block;
  
  vertical-align: middle;
  
}

[hidden] {
  display: none;
}

*, ::before, ::after {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.wcs-pointer-events-none {
  pointer-events: none !important;
}

.wcs-fixed {
  position: fixed !important;
}

.wcs-absolute {
  position: absolute !important;
}

.wcs-relative {
  position: relative !important;
}

.wcs-inset-0 {
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}

.wcs-left-0 {
  left: 0px !important;
}

.wcs-z-10 {
  z-index: 10 !important;
}

.wcs-m-0 {
  margin: 0px !important;
}

.wcs-mt-2 {
  margin-top: 0.5rem !important;
}

.wcs-mt-4 {
  margin-top: 1rem !important;
}

.wcs--mt-2 {
  margin-top: -0.5rem !important;
}

.wcs-mr-2 {
  margin-right: 0.5rem !important;
}

.wcs-mr-4 {
  margin-right: 1rem !important;
}

.wcs-mr-5 {
  margin-right: 1.25rem !important;
}

.wcs-mb-0 {
  margin-bottom: 0px !important;
}

.wcs-mb-1 {
  margin-bottom: 0.25rem !important;
}

.wcs-mb-2 {
  margin-bottom: 0.5rem !important;
}

.wcs-mb-3 {
  margin-bottom: 0.75rem !important;
}

.wcs-mb-4 {
  margin-bottom: 1rem !important;
}

.wcs-mb-5 {
  margin-bottom: 1.25rem !important;
}

.wcs-ml-3 {
  margin-left: 0.75rem !important;
}

.wcs-ml-4 {
  margin-left: 1rem !important;
}

.wcs-ml-5 {
  margin-left: 1.25rem !important;
}

.wcs--ml-4 {
  margin-left: -1rem !important;
}

.wcs-block {
  display: block !important;
}

.wcs-flex {
  display: flex !important;
}

.wcs-hidden {
  display: none !important;
}

.wcs-h-6 {
  height: 1.5rem !important;
}

.wcs-h-8 {
  height: 2rem !important;
}

.wcs-h-16 {
  height: 4rem !important;
}

.wcs-h-32 {
  height: 8rem !important;
}

.wcs-h-full {
  height: 100% !important;
}

.wcs-w-6 {
  width: 1.5rem !important;
}

.wcs-w-8 {
  width: 2rem !important;
}

.wcs-w-16 {
  width: 4rem !important;
}

.wcs-w-20 {
  width: 5rem !important;
}

.wcs-w-32 {
  width: 8rem !important;
}

.wcs-w-auto {
  width: auto !important;
}

.wcs-w-1\/2 {
  width: 50% !important;
}

.wcs-w-3\/4 {
  width: 75% !important;
}

.wcs-w-2\/6 {
  width: 33.333333% !important;
}

.wcs-w-8\/12 {
  width: 66.666667% !important;
}

.wcs-w-full {
  width: 100% !important;
}

.wcs-flex-1 {
  flex: 1 1 0% !important;
}

.wcs-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.wcs-flex-grow {
  flex-grow: 1 !important;
}

@keyframes wcs-spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes wcs-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes wcs-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes wcs-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.wcs-cursor-pointer {
  cursor: pointer !important;
}

.wcs-flex-col {
  flex-direction: column !important;
}

.wcs-flex-col-reverse {
  flex-direction: column-reverse !important;
}

.wcs-flex-wrap {
  flex-wrap: wrap !important;
}

.wcs-items-start {
  align-items: flex-start !important;
}

.wcs-items-center {
  align-items: center !important;
}

.wcs-justify-end {
  justify-content: flex-end !important;
}

.wcs-justify-center {
  justify-content: center !important;
}

.wcs-justify-between {
  justify-content: space-between !important;
}

.wcs-overflow-hidden {
  overflow: hidden !important;
}

.wcs-overflow-scroll {
  overflow: scroll !important;
}

.wcs-rounded {
  border-radius: 0.25rem !important;
}

.wcs-rounded-lg {
  border-radius: 0.5rem !important;
}

.wcs-rounded-full {
  border-radius: 9999px !important;
}

.wcs-rounded-t {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.wcs-rounded-b {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.wcs-border-0 {
  border-width: 0px !important;
}

.wcs-border-2 {
  border-width: 2px !important;
}

.wcs-border {
  border-width: 1px !important;
}

.wcs-border-t-0 {
  border-top-width: 0px !important;
}

.wcs-border-t-2 {
  border-top-width: 2px !important;
}

.wcs-border-r-2 {
  border-right-width: 2px !important;
}

.wcs-border-b {
  border-bottom-width: 1px !important;
}

.wcs-border-solid {
  border-style: solid !important;
}

.wcs-border-transparent {
  border-color: transparent !important;
}

.wcs-border-main {
  --tw-border-opacity: 1 !important;
  border-color: rgba(204, 0, 0, var(--tw-border-opacity)) !important;
}

.wcs-border-accent {
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 68, 83, var(--tw-border-opacity)) !important;
}

.wcs-border-aaccent {
  border-color: rgba(74, 85, 104, 0.5) !important;
}

.wcs-border-gray-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity)) !important;
}

.wcs-border-red-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

.wcs-border-blue-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity)) !important;
}

.focus\:wcs-border-accent:focus {
  --tw-border-opacity: 1 !important;
  border-color: rgba(59, 68, 83, var(--tw-border-opacity)) !important;
}

.wcs-bg-transparent {
  background-color: transparent !important;
}

.wcs-bg-awhite {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.wcs-bg-accent {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 68, 83, var(--tw-bg-opacity)) !important;
}

.wcs-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.wcs-bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity)) !important;
}

.wcs-bg-yellow-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity)) !important;
}

.wcs-bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity)) !important;
}

.hover\:wcs-bg-aaccent:hover {
  background-color: rgba(74, 85, 104, 0.5) !important;
}

.wcs-bg-contain {
  background-size: contain !important;
}

.wcs-bg-no-repeat {
  background-repeat: no-repeat !important;
}

.wcs-p-0 {
  padding: 0px !important;
}

.wcs-p-1 {
  padding: 0.25rem !important;
}

.wcs-p-2 {
  padding: 0.5rem !important;
}

.wcs-p-3 {
  padding: 0.75rem !important;
}

.wcs-p-4 {
  padding: 1rem !important;
}

.wcs-p-5 {
  padding: 1.25rem !important;
}

.wcs-p-8 {
  padding: 2rem !important;
}

.wcs-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.wcs-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.wcs-px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.wcs-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.wcs-py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.wcs-py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.wcs-py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.wcs-text-center {
  text-align: center !important;
}

.wcs-text-right {
  text-align: right !important;
}

.wcs-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.wcs-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.wcs-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.wcs-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.wcs-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.wcs-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.wcs-font-light {
  font-weight: 300 !important;
}

.wcs-font-normal {
  font-weight: 400 !important;
}

.wcs-font-medium {
  font-weight: 500 !important;
}

.wcs-font-semibold {
  font-weight: 600 !important;
}

.wcs-font-bold {
  font-weight: 700 !important;
}

.wcs-italic {
  font-style: italic !important;
}

.wcs-leading-none {
  line-height: 1 !important;
}

.wcs-leading-normal {
  line-height: 1.5 !important;
}

.wcs-text-accent {
  --tw-text-opacity: 1 !important;
  color: rgba(59, 68, 83, var(--tw-text-opacity)) !important;
}

.wcs-text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.wcs-text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity)) !important;
}

.wcs-text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgba(31, 41, 55, var(--tw-text-opacity)) !important;
}

.wcs-text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgba(248, 113, 113, var(--tw-text-opacity)) !important;
}

.wcs-text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(239, 68, 68, var(--tw-text-opacity)) !important;
}

.wcs-text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.wcs-text-red-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

.wcs-text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgba(5, 150, 105, var(--tw-text-opacity)) !important;
}

.hover\:wcs-text-red-600:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(220, 38, 38, var(--tw-text-opacity)) !important;
}

.wcs-underline {
  text-decoration: underline !important;
}

.wcs-opacity-50 {
  opacity: 0.5 !important;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.wcs-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

geronigo-session-bookit p {
  margin: 0px !important;
  text-align: left !important;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

geronigo-session-bookit input, geronigo-session-bookit textarea {
  font-weight: 400 !important;
}

.ng-invalid.ng-touched {
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}

geronigo-session-bookit input[type='checkbox'].ng-invalid.ng-dirty + span {
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

geronigo-session-bookit input[type='checkbox'].ng-invalid.ng-touched + span {
  --tw-text-opacity: 1 !important;
  color: rgba(185, 28, 28, var(--tw-text-opacity)) !important;
}

@media (min-width: 640px) {

  .sm\:wcs-mr-0 {
    margin-right: 0px !important;
  }

  .sm\:wcs-mr-5 {
    margin-right: 1.25rem !important;
  }

  .sm\:wcs-mb-0 {
    margin-bottom: 0px !important;
  }

  .sm\:wcs-block {
    display: block !important;
  }

  .sm\:wcs-w-48 {
    width: 12rem !important;
  }

  .sm\:wcs-w-auto {
    width: auto !important;
  }

  .sm\:wcs-w-3\/12 {
    width: 25% !important;
  }

  .sm\:wcs-flex-1 {
    flex: 1 1 0% !important;
  }

  .sm\:wcs-flex-row {
    flex-direction: row !important;
  }

  .sm\:wcs-flex-nowrap {
    flex-wrap: nowrap !important;
  }

  .sm\:wcs-items-center {
    align-items: center !important;
  }
}

@media (min-width: 768px) {

  .md\:wcs-mt-0 {
    margin-top: 0px !important;
  }

  .md\:wcs-mb-0 {
    margin-bottom: 0px !important;
  }

  .md\:wcs-ml-2 {
    margin-left: 0.5rem !important;
  }

  .md\:wcs-h-auto {
    height: auto !important;
  }

  .md\:wcs-w-40 {
    width: 10rem !important;
  }

  .md\:wcs-w-auto {
    width: auto !important;
  }

  .md\:wcs-w-1\/2 {
    width: 50% !important;
  }

  .md\:wcs-w-1\/3 {
    width: 33.333333% !important;
  }

  .md\:wcs-flex-1 {
    flex: 1 1 0% !important;
  }

  .md\:wcs-flex-row {
    flex-direction: row !important;
  }

  .md\:wcs-flex-wrap {
    flex-wrap: wrap !important;
  }

  .md\:wcs-items-center {
    align-items: center !important;
  }

  .md\:wcs-justify-center {
    justify-content: center !important;
  }

  .md\:wcs-px-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .md\:wcs-py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .md\:wcs-pr-1 {
    padding-right: 0.25rem !important;
  }

  .md\:wcs-pr-2 {
    padding-right: 0.5rem !important;
  }

  .md\:wcs-pl-1 {
    padding-left: 0.25rem !important;
  }

  .md\:wcs-pl-2 {
    padding-left: 0.5rem !important;
  }

  .md\:wcs-text-base {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }
}